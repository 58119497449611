<template>
  <div>
    <div class="flex flex-col">
      <div class="mt-4 ml-4 mr-4 mb-1 text-secondary-grey font-medium pl-12">Please search and select a location</div>
      <div class="mt-1 ml-4 mr-4 mb-2 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 content-center">
        <div class="flex flex-col">
          <div>
            <label for="location" class="sr-only">Search location</label>
            <input type="text" name="location" id="location" v-model="search_location" 
                   class="shadow-sm focus:ring-primary-navy focus:border-primary-navy block w-full sm:text-sm border-gray-300 rounded-md"
                   placeholder="Search location" >
          </div>

          <div class="grid grid-cols-2 gap-1 md:gap-2 mt-2" v-if="locations !== null">
            <button v-for="(l, i) in locations"
                 class="rounded-sm bg-white text-center text-secondary-grey border-accent-green border p-3 hover:bg-accent-green-50"
                 :key="i"
                 @click="$emit('on_select', l);"
                 >{{ l.name }}, {{ l.country }}
            </button>
          </div>

          <div class="mt-4" v-if="locations === null">
            <p>Loading data, please be patient</p>
          </div>
        </div>

        <!-- Map -->
        <div class="bg-opacity-0" id="map-container">
          <svg id="map" class="h-96 w-full stroke-2">
            <g v-if="world !== null && path !== null">
              <path v-for="(feature, index) in world.features"
                    :key="index"
                    class="fill-current text-gray-200"
                    :d="path(feature)"
                />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: 'SelectLocation',
  props: ['search', 'locations', 'world'],
  data: function() {
    return { 
      search_value: '', 
      path: null,
    };
  },
  mounted: function() { 
    try {
      this.search_value = this.search; 

      // Map
      const map = document.getElementById("map-container");
      let projection = d3.geoNaturalEarth1().scale(200);
      const of = projection.invert([0.5*map.clientWidth, 0.5*map.clientHeight]);
      projection = projection.center([-of[0], -of[1]]);
      this.path = d3.geoPath().projection(projection);
    } catch (e) {
      this.on_error("Error in SelectLocation::mounted", e);
    }
  },

  computed: {
    search_location: {
      get: function() { return this.search_value; },
      set: function(v) {
        this.search_value = v;
        this.$emit('update_search', v);
      }
    },
  },
  methods: {
    on_error: function(msg, e) {
      this.$emit("on_error", {message: msg, error: e});
    }
  }
}
</script>

