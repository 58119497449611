<template>
  <div class=" pl-4 pr-6 py-2 border-l-[6px]" 
      :class="classObject">
    <p><slot></slot></p>
  </div>
</template>

<script>
export default {
  name: "ExpandInfoBody",
  props: ["focused"],
  computed: {
    classObject() {
      return {
        'border-primary-navy-650 bg-primary-navy-50 rounded-r-3xl': this.focused === true,
        'border-primary-navy-50': ! this.focused === true
      }
    }
  }
}
</script>
