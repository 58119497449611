<!-- Generator: Adobe Illustrator 28.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 708.2 190.1" xml:space="preserve">
<g id="Layer_1">
	<g>
		<polygon class="st0" points="43.5,79.2 43.5,38.6 43.5,0 0,0.1 0,190 43.5,190 43.5,118.3 		"/>
		<path class="st0" d="M163.2,118c15.1,0,26-2.9,32.8-8.8c6.8-5.9,10.2-15.2,10.2-28V36.6c0-12.9-3.4-22.3-10.2-28.1
			C189.2,2.7,178.2,0,163.2,0H53v38.6c31.1,0,62.1,0,93.2,0c1.4,0,7.8,0.3,13.6,5.3c1.4,1.2,3.1,2.5,4.5,6.3
			c0.9,2.2,0.9,13.2,0,17.4c-0.1,0.7-0.4,1.4-0.6,2.1c-1.9,4.7-6.3,8-11.3,9c-2.8,0.6-5.4,0.6-6.7,0.6c-30.9,0-61.9,0-92.8,0v39.1
			c10.1,0,20.2,0,30.3,0c21.4,23.9,42.8,47.9,64.2,71.8c20.9,0,41.8,0,62.7-0.1c-23.1-23.9-46.3-47.8-69.4-71.7L163.2,118z"/>
		<path class="st0" d="M410.2,111.9c0-13,0-26,0-39.1c-38.5,0-77,0-115.5,0v39C333.2,111.9,371.7,111.9,410.2,111.9z"/>
		<path class="st0" d="M241,0.2v189.9c14.7,0,29.5,0,44.2,0V0.2H241z"/>
		<path class="st0" d="M438.6,38.6c0-12.8,0-25.6,0-38.4H294.7v38.4C342.6,38.6,390.6,38.6,438.6,38.6z"/>
		<path class="st0" d="M438.6,190.1c0-13.3,0-26.5,0-39.8c-48,0-95.9,0-143.9,0v39.8C342.6,190.1,390.6,190.1,438.6,190.1z"/>
		<path class="st0" d="M680.1,24.6C661.3,8.3,634.5,0.2,599.6,0.2H528v38.4c24.8,0,53.6,0,77.6,0c23.7,1.3,44.1,14.5,52.8,34.3
			c1.2,2.8,8.4,19.8,1.5,39.1c-7.5,21.2-25.4,36.5-53.9,38.3c-24.1,0-53.1,0-78,0v39.8h60.3c19.2,0,34.9-1.2,47.2-3.5
			c12.3-2.3,22.6-6.1,30.8-11.2c13.3-8.1,23.6-19.3,30.9-33.5c7.3-14.2,11-30.2,11-47.9C708.2,64,698.8,40.9,680.1,24.6z"/>
		<path class="st0" d="M518.5,38.6V0.2h-44.2v189.9h44.2v-39.8C518.5,113,518.5,75.8,518.5,38.6z"/>
	</g>
</g>
</svg>
</template>
<script setup>
</script>
