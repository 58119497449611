<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="rounded-md bg-red-50 p-2">
    <div class="flex">
      <div class="flex-shrink-0">
        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          Sorry, the following error occured:
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc pl-5 space-y-1">
            <li id="alert-with-description-message" v-if="typeof message === 'string' && message !== ''">
              {{ message }}
            </li>
            <li id="alert-with-description-error" v-if="error !== null && typeof error.message === 'string' && error.message !== ''">
              Error code: {{ error.message }}
            </li>
            <li id="alert-with-description-errorlocation" v-if="has_details()">
              Error location: {{ error.filename }} @ line {{ error.lineNumber }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XCircleIcon from "@/icons/XCircleIcon.vue";

export default {
  components: {
    XCircleIcon,
  },
  props: [ 'message', 'error' ],
  methods: {
    has_details: function() {
      return (this.error !== null && this.error.filename && this.error.lineNumber );
    }
  }
}
</script>
