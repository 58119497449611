<template>
  <div class="p-2 mt-2 border-accent-skyblue border-t border-b rounded-lg">
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-col lg:w-1/3">
        <div class="pl-4 mb-2 font-extrabold" :id="`viewdckpi-title-${index}`">{{ information["title"] }}</div>
        <div class="grid grid-cols-2 border-t border-accent-skyblue">
          <div>Heat rejection:</div>    <div :id="`viewdckpi-heatrejection-${index}`">{{ information["heat_rejection"] }}</div>
          <div>Aisle containment:</div> <div :id="`viewdckpi-aislecontainment-${index}`">{{ information["aisle_containment"] }}</div>
          <div>Supply temperature:</div><div :id="`viewdckpi-aisletemperature-${index}`">{{ information["aisle_temperature"] }}</div>
        </div>
        <div class="mt-2 border-t border-accent-skyblue" :id="`viewdckpi-description-${index}`">{{ information["description"] }}</div>
      </div>
      <div class="flex-grow"></div>
      <div class="lg:w-2/3 lg:pl-2 lg:flex lg:flex-col text-center"> <!-- 2xl:flex-row-reverse 2xl:items-start 2xl:pt-12 text-center" -->
        <div class="hidden lg:block w-full max-w-lg mx-auto h-68" v-if="show_chart">
          <canvas :id="chart_id"></canvas>
        </div>

        <div class="mt-2 md:mt-4 2xl:mt-2 max-w-sm mx-auto">
        <!-- Table with a single result -->
        <table class="divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" v-if="has_multiple_results === false">
          <caption class="text-sm">Estimated annualised design PUE (dPUE) for {{ information["title"] }}</caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 -py-3 text-center text-xs font-medium uppercase tracking-wider">Condition</th>
              <th scope="col" class="px-6 -py-3 text-center text-xs font-medium uppercase tracking-wider">dPUE</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-accent-skyblue-150">
              <td class="px-6 py-1 whitespace-nowrap text-sm font-medium">Annualised</td>
              <td class="px-6 py-1 whitespace-nowrap text-sm" :id="`viewdckpi-annual-${index}`">{{ results[0].annual.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Table with multiple results [Tier level] -->
        <table class="divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" v-if="has_multiple_results === true">
          <caption class="text-sm">Estimated annualised design PUE (dPUE) for {{ information["title"] }}</caption>
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 -py-3 text-center text-xs font-medium uppercase tracking-wider"
                  v-for="(result, i) in results"
                  :key="i"
                  > {{ result.topology.tier }} </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-accent-skyblue-150">
              <td class="px-6 py-1 whitespace-nowrap text-sm" 
                  v-for="(result, i) in results"
                  :key="i"
                  :id="`viewdckpi-annual-${index}-${i}`"
                  >{{ result.annual.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@/../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

import Chart from 'chart.js/auto';

export default {
  name: "ViewDcKpi",
  props: {
    index: {
      required: false
    },
    information: {
      required: true
    },
    results: {
      required: true
    },
    show_chart: {
      default: true
    }
  },
  data: function() {
    return {
      my_chart: null,
    };
  },
  mounted: function() {
    let c = document.getElementById(this.chart_id);
    if (this.show_chart) {
      this.my_chart = new Chart(c, {
        type: 'bar',
        data: this.data,
        options: {
        }
      });
    }
  },
  computed: {
    has_multiple_results: function() {
      try {
        return this.results.length > 1;
      } catch (e) {
        this.on_error("Error in multi_results", e);
      }
      return false;
    },
    chart_id: function() { return `viewdckpi-chart-${this.index}`; },
    data: function() {
      let colors = [
          fullConfig.theme.colors['accent-skyblue'].DEFAULT,
          fullConfig.theme.colors['accent-teal'].DEFAULT,
          fullConfig.theme.colors['accent-green'].DEFAULT,
          fullConfig.theme.colors['accent-lime'].DEFAULT,
          fullConfig.theme.colors['accent-yellow'].DEFAULT,
        ];
      return {
        labels: ['Annualised'],
        datasets: this.results
          .map((result, i) => {
            let ii = i % colors.length; // Cycle colors when more results than colors
            return {
                label: (this.has_multiple_results ? result.topology.tier : 'dPUE'),
                data: [result.annual],
                backgroundColor:[colors[ii]]
              };
           }),
      };
    }
  },
  methods: {
  }
}
</script>
