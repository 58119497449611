const climate_types = {
  "A": {
    "shortDesc": "Tropical",
    "longDesc": "Tropical climates are characterized by constant high temperatures (at sea level and low elevations); all 12 months of the year have average temperatures of 18 °C (64.4 °F) or higher.",
  },
  "B": {
    "code": "B",
    "shortDesc": "Dry",
    "longDesc": "D climates are characterized by low precipitation.",
  },
  "C": {
    "code": "C",
    "shortDesc": "Temperate",
    "longDesc": "Temperate (C) climates are defined as having an average temperature between -3°C and 18°C in their coldest month, with at least one month averaging above 10°C",
  },
  "D": {
    "code": "D",
    "shortDesc": "Continental",
    "longDesc": "These climates have an average temperature above 10°C in their warmest months, and a coldest month average below −3 °C. These usually occur in the interiors of continents and on their upper east coasts, normally north of 40°N. In the Southern Hemisphere, group D climates are extremely rare due to the smaller land masses in the middle latitudes and the almost complete absence of land at 40–60°S, existing only in some highland locations.",
  },
  "E": {
    "code": "E",
    "shortDesc": "Polar",
  }
};


export function get_climate_type(key) {
  if (typeof key === 'string' && key.length > 0) {
    return climate_types[key.charAt(0)];
  } else {
    return null;
  }
}

const climate_subtypes = 
{
  "Af": 
      {
        "code": "Af",
        "shortDesc": "Rainforest",
        "longDesc": "Rainforest climates usually occur within 10° latitude of the equator. This climate is dominated by the doldrums low-pressure system all year round, so has no natural seasons in terms of thermal and moisture changes. All 12 months have average precipitation of at least 60mm.",
        "colour": "#9A0002"
      },
  "Am":
      {
        "code": "Am",
        "shortDesc": "Monsoon",
        "longDesc": "Am climates, most common in South Asia, result from the monsoon winds which change direction according to the seasons. This climate has a driest month (which nearly always occurs at or soon after the 'winter' solstice for that side of the equator) with rainfall less than 60 mm, but more than 1/25 the total annual precipitation.",
        "colour": "#FD0000"
      },
  "Aw":
      {
        "code": "Aw",
        "shortDesc": "Savanna",
        "longDesc": "Aw climates have a pronounced dry season, with the driest month having precipitation less than 60 mm and less than 1/25 of the total annual precipitation.",
        "colour": "#FDCEC8"
      },
  "BW":
      {
        "code": "BW",
        "shortDesc": "Desert",
        "longDesc": "Hot or cold desert climate",
      },
  "BWk":
          {
          "code": "BWk",
          "shortDesc": "Cold",
          "longDesc": "Cold desert climate",
          "colour": "#F3F96D"
          },
  "BWh":
          {
            "code": "BWh",
            "shortDesc": "Hot",
            "longDesc": "Hot desert climate",
            "colour": "#FFC904"
          },
  "BS":
      {
        "code": "BS",
        "shortDesc": "Semi-Arid",
      },
  "BSk":
          {
            "code": "BSk",
            "shortDesc": "Cold",
            "longDesc": "Cold semi-arid climate",
            "colour": "#CCAA52"
          },
  "BSh":
          {
            "code": "BSh",
            "shortDesc": "Hot",
            "longDesc": "Hot semi-arid climate",
            "colour": "#CB9418"
          },
  "Cf":
      {
        "code": "Cf",
        "shortDesc": "Temperate With No Dry Season",
        "longDesc": "Cf climates usually occur on the eastern sides of continents, usually in high 20s and 30s latitudes. Unlike the dry summer Mediterranean climates, humid subtropical climates have a warm and wet flow from the tropics that creates warm and moist conditions in the summer months. As such, summer (not winter as is the case in Mediterranean climates) is often the wettest season.",
      },
  "Cfa":
          {
            "code": "Cfa",
            "shortDesc": "Humid Sub-Tropical",
            "longDesc": "Humid sub-tropical climate, in which the coldest month averages above -3°C, at least one month averages above 22°C and at least four months average above 10°C. No notable difference in precipitation between seasons, and no dry months in the summer.",
            "colour": "#033000"
          },
  "Cfb":
          {
            "code": "Cfb",
            "shortDesc": "Temperate Oceanic",
            "longDesc": "Cfb climates usually occur in the higher middle latitudes on the western sides of continents. They are typically situated immediately poleward of the Mediterranean climates. These climates are dominated all year round by the polar front, leading to changeable, often overcast weather. Summers are cool due to cool ocean currents, but winters are milder than other climates in similar latitudes, but usually very cloudy. Cfb climates are also encountered at high elevations in certain subtropical and tropical areas and are called 'highlands'.",
            "colour": "#005002"
          },
  "Cfc":
          {
            "code": "Cfc",
            "shortDesc": "Subpolar Oceanic",
            "longDesc": "Subpolar oceanic climates (Cfc) occur poleward of or at higher elevations than the maritime temperate climates, and are mostly confined either to narrow coastal strips on the western poleward margins of the continents, or, especially in the Northern Hemisphere, to islands off such coasts. They occur in both hemispheres, most often at latitudes from 60° north and south to 70° north and south.",
            "colour": "#087700"
          },
  "Cs":
        {
            "code": "Cs",
            "shortDesc": "Semi-Arid / 'Mediterranean' Climates",
        },
  "Csa":
              {
                "code": "Csa",
                "shortDesc": "Hot Summer Mediterranean",
                "longDesc": "These climates usually occur on the western sides of continents between the latitudes of 30° and 45°. These climates are in the polar front region in winter, and thus have moderate temperatures and changeable, rainy weather. Summers are hot and dry, due to the domination of the subtropical high pressure systems, except in the immediate coastal areas, where summers are milder due to the nearby presence of cold ocean currents that may bring fog but prevent rain.",
                "colour": "#04FC02"
              },
  "Csb":
              {
                "code": "Csb",
                "shortDesc": "Warm Summer Mediterranean",
                "longDesc": "Dry-summer sometimes extends to additional areas not typically associated with a typical Mediterranean climate, as their warmest month mean doesn't reach 22°C, they are classified as (Csb). Some of these areas would border the Oceanic climate (Cfb), except their dry-summer patterns meet Köppen's Cs minimum thresholds.",
                "colour": "#95FF00"
              },
  "Csc":
              {
                "code": "Csc",
                "shortDesc": "Cool Summer Mediterranean",
                "longDesc": "Cold-summer Mediterranean climates (Csc) exist in high-elevation areas adjacent to coastal Csb climate areas, where the strong maritime influence prevents the average winter monthly temperature from dropping below 0 °C. This climate is rare and is predominantly found in climate fringes and isolated areas of the Cascades and Andes Mountains, as the dry-summer climate extends further poleward in the Americas than elsewhere.",
                "colour": "#BEFC00"
              },
  "Cw":
            {
            "code": "Cw",
            "shortDesc": "Sub-Tropical",
            },
  "Cwa":
              {
                "code": "Cwa",
                "shortDesc": "Humid",
                "longDesc": "Subtropical-Dry Winter (Cwa) is monsoonal influenced, having the classic dry winter/wet summer pattern associated with tropical monsoonal climates.",
                "colour": "#A55604"
              },
  "Cwb":
              {
                "code": "Cwb",
                "shortDesc": "Highland",
                "longDesc": "This is a type of climate mainly found in highlands inside the tropics of Mexico, Peru, Bolivia, Madagascar, Zambia, Zimbabwe and South Africa, but it is also found in east-central Spain or central Argentina and areas outside of the tropics. Winters are noticeable and dry, and summers can be very rainy. In the tropics, the rainy season is provoked by the tropical air masses and the dry winters by subtropical high pressure.",
                "colour": "#986204"
              },
  "Cwc":
              {
                "code": "Cwc",
                "shortDesc": "Cold Highland",
                "longDesc": "",
                "colour": "#5B3D00"
              },
  "Df":
      {
        "code": "Df",
        "shortDesc": "Humid",
      },
  "Dfa":
          {
          "code": "Dfa",
          "shortDesc": "Hot Summer",
          "longDesc": "",
          "colour": "#320032"
          },
  "Dfb":
          {
            "code": "Dfb",
            "shortDesc": "Warm Summer",
            "longDesc": "",
            "colour": "#660065"
          },
  "Dfc":
          {
            "code": "Dfc",
            "shortDesc": "Sub-Arctic",
            "longDesc": "",
            "colour": "#C700D1"
          },
  "Dfd":
          {
            "code": "Dfd",
            "shortDesc": "Extremely Cold Sub-Arctic",
            "longDesc": "",
            "colour": "#C51386"
          },
  "Ds":
      {
        "code": "Ds",
        "shortDesc": "Dry-Summer",
      },
  "Dsa":
          {
          "code": "Dsa",
          "shortDesc": "Hot",
          "longDesc": "",
          "colour": "#FD64FA"
          },
  "Dsb":
          {
            "code": "Dsb",
            "shortDesc": "Warm",
            "longDesc": "",
            "colour": "#FCB8FD"
          },
  "Dsc":
          {
            "code": "Dsc",
            "shortDesc": "Sub-Arctic",
            "longDesc": "",
            "colour": "#E4C7FE"
          },
  "Dsd":
          {
            "code": "Dsd",
            "shortDesc": "Extremely Cold Sub-Arctic",
            "longDesc": "",
            "colour": "#C6C6C6"
          },
  "Dw":
      {
        "code": "Dw",
        "shortDesc": "Monsoon-Influenced",
      },
  "Dwa":
          {
          "code": "Dwa",
          "shortDesc": "Hot Summer",
          "longDesc": "A variant of the DW climate which has dry winters and hence relatively lower snowfall with monsoonal type summer rainfall, is to be found in north-eastern China including coastal regions of the Yellow Sea and over much of the Korean Peninsula; it has the Köppen classification Dwa.",
          "colour": "#C7B5F6"
          },
  "Dwb":
          {
            "code": "Dwb",
            "shortDesc": "Warm Summer",
            "longDesc": "",
            "colour": "#997CB5"
          },
  "Dwc":
          {
            "code": "Dwc",
            "shortDesc": "Sub-Arctic",
            "longDesc": "",
            "colour": "#79569B"
          },
  "Dwd":
          {
            "code": "Dwd",
            "shortDesc": "Extremely Cold Sub-Arctic",
            "longDesc": "",
            "colour": "#6D23B6"
          },
  "ET":
      {
        "code": "ET",
        "shortDesc": "Mild Tundra",
        "longDesc": "",
        "colour": "#62FFFE"
      },
  "EF":
      {
        "code": "EF",
        "shortDesc": "Ice Cap",
        "longDesc": "",
        "colour": "#6690FF"
      }
};


export function get_climate_subtype(key) {
  if (typeof key === 'string') {
    return climate_subtypes[key];
  } else {
    return null;
  }
}
