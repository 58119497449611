<template>
  <div id="title"
       class="group flex flex-row py-2 cursor-pointer pl-4 -mr-4
              hover:bg-primary-navy-50 hover:pl-8 hover:rounded-l-lg"
      :class="{'bg-primary-navy-50 pl-8 rounded-l-lg border-r-4 border-primary-navy-650': isClicked}"
      @mouseenter="$emit('onMouseEnter')"
      @mouseleave="$emit('onMouseLeave')"
      @click="$emit('onClick')">
    <h1 class="ml-4 self-center shrink-0 group-hover:font-semibold" 
       :class="{'font-bold': isClicked}">
      <slot></slot>
    </h1>
    <div class="basis-full transition group-hover:translate-x-full group-hover:duration-150 mr-10"
        :class="{'translate-x-full duration-150': isClicked,
                 'translate-x-0 duration-200': !isClicked}">
      <ChevronRightIcon class="w-8 inline"/>
    </div>
  </div>
</template>

<script>
import ChevronRightIcon from "@/icons/ChevronRightIcon.vue";

export default {
  name: "ExpandInfoTitle",
  components: {
    ChevronRightIcon,
  },
  props: {
    isClicked: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['onMouseEnter', 'onMouseLeave', 'onClick'],
}
</script>
