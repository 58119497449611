<template>
  <div>
    <div class="flex flex-col text-secondary-grey">
      <div class="mt-4 ml-4 mr-4 mb-1 font-medium pl-12" v-if="location !== null">Indicative Data Centre KPIs evaluated for {{ location["Station Name"] }}</div>

      <div class="ml-4 mr-4 mt-3 flex flex-col" v-if="ready">
         <ViewDcKpi v-for="(topology, index) in topologies"
              :key="index"
              :index="index"
              :information="topology"
              :results="results(topology)"
              ></ViewDcKpi>
      </div>
    </div>
  </div>
</template>
<script>

import ViewDcKpi from "@/components/ViewDcKpi.vue";

export default {
  name: "EvaluateDPue",
  components: {
    ViewDcKpi
  },
  props: {
    location: {
      required: true
    }
  },
  data: function() {
    return {
      p_topologies: null,
      p_results: null,
    };
  },
  computed: {
    ready: function() {
      return this.p_topologies !== null && this.p_results !== null;
    },
    topologies: function() {
      try {
        // Build a new array of topology with unique title [in original order]
        let r = []; let treated = {};
        this.p_topologies.forEach((topology) => { if (! (topology.title in treated)) { r.push(topology); treated[topology.title] = true; } });
        return r;
      } catch (e) {
        this.on_error("Error retrieving results", e);
      }
      return [];
    },
  },
  mounted: function() {
    try {
      let lbl_topologies = "topologies"; let lbl_annual = "annual PUE";
      let loading_elements = [lbl_topologies, lbl_annual];
      let loaded_elements = loading_elements.reduce((map, e) => {map[e] = false; return map}, {});
      let loading_update = () => {
        this.$emit('on_loading', {message: `Loading ${loading_elements.filter((e) => loaded_elements[e] === false).join(", ")}. Please be patient`});
      };

      loading_update();
      Promise.all([
        fetch('/api/topologies').then((r) => {loaded_elements[lbl_topologies] = true; loading_update(); return r.json();}),
        fetch(`/api/pue/annual?z=${this.location.Elev}&WMO=${this.location.WMO}`).then((r) => {loaded_elements[lbl_annual]= true; loading_update(); return r.json();}),
      ])
      .then(([topologies, annual]) => {
        this.p_topologies = topologies;
        this.p_results = [...this.p_topologies.keys()]
              .map((i) => ({
                        topology: this.p_topologies[i],
                        annual: annual[i],
               }));
        this.$emit('on_loading', false);
      })
      .catch((e) => {this.on_error("Error loading topologies/pue results", e);});
    } catch (e) {
      this.on_error("Error in mounted", e);
    }
  },
  methods: {
    results: function(topology) {
      try {
        return this.p_results.filter((e, i) => this.p_topologies[i].title === topology.title);
      } catch (e) {
        this.on_error("Error retrieving results", e);
      }
      return [];
    },
    on_error: function(msg, e) {
      this.$emit("on_error", {message: msg, error: e});
    }
  }
}
</script>
