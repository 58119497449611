<template>
  <div>
    <div class="flex flex-col text-secondary-grey">
     <div class="mt-4 ml-4 mr-4 mb-1 rounded-sm text-secondary-grey border-accent-green border pl-12 hover:bg-accent-green-50"><a href="#" @click="$emit('on_next')">View dPUE from {{ location["Station Name"] }}</a></div>

     <div class="mt-3 ml-4 mr-4 border-accent-skyblue border-r border-l text-center"> 
       <div>Location: {{ location["Station Name"] }}, {{ location.Country }}</div>
       <div>Station: #{{ location.WMO }}</div>
       <div>Elevation: {{ location.Elev }}m</div>
     </div>

     <div class="mt-3 ml-4 mr-4 p-2 border-accent-skyblue border-r border-l" v-if="is_kgcode_valid"> 
       <div class="pl-4 mt-2 mb-2" v-if="climate_type !== null || climate_subtype !== null">
         <span class="inline-flex items-center px-2.5 py-0.5 mr-4 rounded-full text-s font-medium bg-accent-skyblue font-semibold" v-if="climate_type !== null">
           {{ climate_type.shortDesc }}
         </span>
         <span class="inline-flex items-center px-2.5 py-0.5 ml-4 rounded-full text-s font-medium bg-accent-teal font-medium" v-if="climate_subtype !== null">
           {{ climate_subtype.shortDesc }}
         </span>
       </div>
       <h2 class="font-extrabold pl-4">Koppen Geiger climate classification: {{ kgcode }}</h2>
       <div class="" v-if="climate_type !== null">{{ climate_type.longDesc }}</div>
       <div class="" v-if="climate_subtype !== null">{{ climate_subtype.longDesc }}</div>
     </div>

     <div class="ml-4 mr-4 mt-3 p-2 border-accent-skyblue border-r border-l">
       <h2 class="font-extrabold pl-4">ASHRAE Extremes</h2>
       <p>The extreme weather conditions (dry and wet bulb) are estimates for a 1 in 20 (or 50) years event.</p>
       <div class="mt-2 mb-2 grid grid-cols-5 gap-1 content-start justify-center justify-items-center">
         <div></div>
         <div class="col-span-2">n=20 years</div>
         <div class="col-span-2">n=50 years</div>

         <div></div>
         <div>Min</div>
         <div>Max</div>
         <div>Min</div>
         <div>Max</div>
         
         <div>DB</div>
         <div>{{ location.N20DBMin }}&deg;Cdb</div>
         <div>{{ location.N20DBMax }}&deg;Cdb</div>
         <div>{{ location.N50DBMin }}&deg;Cdb</div>
         <div>{{ location.N50DBMax }}&deg;Cdb</div>

         <div>WB</div>
         <div>{{ location.N20WBMin }}&deg;Cwb</div>
         <div>{{ location.N20WBMax }}&deg;Cwb</div>
         <div>{{ location.N50WBMin }}&deg;Cwb</div>
         <div>{{ location.N50WBMax }}&deg;Cwb</div>

       </div>
     </div>

     <div class="ml-4 mr-4 mt-3 mb-3 p-2 border-accent-skyblue border-r border-l">
       <h2 class="pl-4 font-extrabold">Locations with similar climate</h2>
       <div class="bg-opacity-0 mt-2" id="climate-map-container">
         <svg class="h-96 w-full stroke-0">
           <g v-if="world !== null && path !== null">
             <path v-for="(feature, index) in world.features"
                 :key="index"
                 class="stroke-0 fill-current"
                 :class="{'text-primary-navy': feature.properties.gridcode === kgcode, 'text-gray-200': feature.properties.gridcode !== kgcode}"
                 :d="path(feature)"
               />
           </g>
         </svg>
       </div>
     </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3';
import {get_climate_type, get_climate_subtype} from "@/js/koppengeiger.js";

export default {
  name: "ShowClimate",
  props: {
    location: {
      required: true
    },
    kgcode: {
      required: true
    },
    world: {
      required: true,
    }
  },
  data: function() {
    return {
      path: null
    };
  },
  mounted: function() {
    try {
      const map = document.getElementById("climate-map-container");
      let projection = d3.geoNaturalEarth1().scale(150);
      const offset = projection.invert([0.5*map.clientWidth, 0.5*map.clientHeight]);
      projection = projection.center([-offset[0], -offset[1]]);
      this.path = d3.geoPath().projection(projection);

    } catch (e) {
      this.on_error("Error in ShowClimate::mounted", e);
    }
  },
  computed: {
    is_kgcode_valid: function() {
      return (typeof this.kgcode === 'string' && this.kgcode.length > 0);
    },
    climate_type: function() {
      try {
        return get_climate_type(this.kgcode);
      } catch (e) {
        this.on_error("Error in ShowClimate::climate_type", e);
      }
      return null;
    },
    climate_subtype: function() {
      try {
        return get_climate_subtype(this.kgcode);
      } catch (e) {
        this.on_error("Error in ShowClimate::climate_subtype", e);
      }
      return null;
    }
  },
  methods: {
    on_error: function(msg, e) {
      this.$emit("on_error", {message: msg, error: e});
    }
  }
}
</script>
