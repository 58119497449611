<template>
  <div></div>
</template>
<script setup>
try {
  /* v02 */
navigator.sendBeacon(
  'https://page-view-counter.onecloud.red-eng.com/api/log_view', 
  JSON.stringify({
    name: 'prod-assuRED',
    version: 'v1.6.8'
  })
);
} catch (e) {
  console.error(`Error registering visit: ${e}`);
}
</script>
