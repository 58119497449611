<template>
<a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" 
  href="https://en.wikipedia.org/wiki/Power_usage_effectiveness">
  Power Usage Effectiveness (PUE)</a> 
is a metric assessing the energy efficiency of a data centre using the ratio of the overall data 
centre energy consumption to the energy consumed solely by the IT equipment. The value of PUE can 
vary based on many factors including the data centre design, utilisation rate, and weather.
<br><br>
A lower PUE value indicates a more energy-efficient data centre. Data centres aim to achieve a 
PUE value as close to 1.0 as possible - at which point all the energy provided to the data centre
is consumed by the IT equipment. Depending on the data centre operation, major contributors to higher 
PUE are generally associated with IT equipment cooling systems and UPS systems that provide continuous 
power in the event of power failure and.
<br><br>
External factors such as temperature, humidity and weather patterns exert a significant impact 
on the choice and performance of cooling systems. Consequently, the climate and location play a 
crucial role in determining the PUE of a data centre. RED calculates design PUE of data centres 
throughout design to optimise design choices and equipment selection in respect to energy efficiency 
and sustainability. AssuRED is a tool RED uses at very early design stage to inform topology and 
system selection using sustainability and energy efficiency driven metrics.
</template>
<script>
export default {
  name: "PueInfoBody",
  }
</script>
