<template>
  <div>
    <div class="flex flex-col">
      <div class="mt-4 ml-4 mr-4 mb-1 text-secondary-grey font-medium pl-12" v-if="location !== null">Select WMO weather station for {{ location.name }}, {{ location.country }}</div>
      <div class="mt-1 ml-4 mr-4 mb-2 grid grid-cols-1 lg:grid-cols-2 gap-2 content-center">
        <div class="flex flex-col">
          <!-- Pagination element -->
          <nav class="border-b border-gray-200 px-4 flex items-center justify-between sm:px-0" v-if="this.n_pages > 0">
            <div class="-mt-px w-0 flex-1 flex">
              <a href="#" class="border-b-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300" 
                id="selectwmo-pagination-previous"
                @click="page = page - 1"
                v-if="page > 0">
                <!-- Heroicon name: solid/arrow-narrow-left -->
                <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                </svg>
                Previous
              </a>
            </div>
            <div class="hidden md:-mt-px md:flex" v-for="i in n_pages" 
                 :key="i"
              >
              <a href="#" 
                 :class="{'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 pt-4 px-4 inline-flex items-center text-sm font-medium': page !== (i-1),
                          'border-indigo-500 text-indigo-600 border-b-2 pt-4 px-4 inline-flex items-center text-sm font-medium': page === (i-1)}" 
                 :aria-current="page === (i-1) ? 'page' : ''"
                 :id="`selectwmo-pagination-page${i}`"
                 @click="page = i-1"
                 >
                {{ i }}
              </a>
            </div>
            <div class="-mt-px w-0 flex-1 flex justify-end">
              <a href="#" class="border-b-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300" 
                id="selectwmo-pagination-next"
                @click="page = page + 1"
                v-if="page < n_pages-1">
                Next
                <!-- Heroicon name: solid/arrow-narrow-right -->
                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </nav>
          <!-- End of pagination -->

          <button v-for="l in list_in_page"
            class="rounded-sm bg-white text-secondary-grey border-accent-green border text-center mt-2 p-3 hover:bg-accent-green-50"
            :key="l.WMO"
            :id="`selectwmo-wmo${l.WMO}`"
            @click="$emit('on_select', l)"
            @mouseover="hovered_wmo = l.WMO"
            @mouseleave="hovered_wmo = null"
            >
            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-2 gap-2">
              <div>{{ l["Station Name"] }} [WMO#{{ l.WMO }}]</div>
              <div class="hidden md:block lg:hidden">Distance: {{ l.distance.toFixed(0) }}km</div>
              <div>Elevation: {{ l.Elev }}m</div>
            </div>
          </button>


        </div>
        <div class="bg-opacity-0" id="map-container">
          <svg id="map" class="h-96 w-full stroke-1">
            <g v-if="world !== null && path !== null">
              <path v-for="(feature, index) in world.features"
                    :key="index"
                    class="fill-current text-gray-200"
                    :d="path(feature)"
                />
              <circle :cx="projection([location.lon, location.lat])[0]"
                      :cy="projection([location.lon, location.lat])[1]"
                      r="5px"
                      class="fill-current text-assured-blue-900"
                />
              <text   :x="projection([location.lon, location.lat])[0]+6"
                      :y="projection([location.lon, location.lat])[1]+6"
                >{{ location.name }}</text>
              <circle v-for="l in list"
                     :key="l.WMO"
                     :cx="projection([l.Lon, l.Lat])[0]"
                     :cy="projection([l.Lon, l.Lat])[1]"
                     r="3px"
                     class="fill-current text-primary-navy-50 stroke-primary-navy-650"
                     :class="{'text-accent-green-650 stroke-2': l.WMO === hovered_wmo}"
                />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";

export default {
  name: "SelectWmo",
  props: {
    location: {
      required: true,
      default: {}
    },
    ashrae_locations: {
      default: null
    },
    world: {
      default: null
    }
  },
  data: function() {
    return { 
      list: [],
      path: null,
      projection: null,
      offset: null,
      hovered_wmo: null,
      c_page: 0,
      n_locations_per_page: 5,
    };
  },
  mounted: function() {
    /* Retrieve list of 20 closest ashrae locations */
    try {
      this.list = this.ashrae_locations
          .map((l) => { 
            return {...l, distance: this.getDistanceInKm(this.location, {lat: l.Lat, lon: l.Lon})};
          })
          .filter((l) => l.distance !== undefined)
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 20);

       // Map
       this.set_path(this.location);

    } catch (e) {
      this.on_error("Error in mounted", e);
    }
  },
  computed: {
    n_pages: function() {
      return Math.floor(this.list.length / this.n_locations_per_page);
    },
    page: {
     get: function() { return Math.max(Math.min(this.c_page, this.n_pages), 0); },
     set: function(v) { this.c_page = Math.max(Math.min(v, this.n_pages), 0); }
    },
    list_in_page: function() {
      const n = this.n_locations_per_page;
      const start = this.page*n; const end = (this.page+1)*n;
      return this.list.slice(start, end);
    }
  },
  methods: {
    set_path: function(c) {
      const map = document.getElementById("map-container");
      let projection = d3.geoNaturalEarth1().scale(1000);
      this.offset = projection.invert([0.5*map.clientWidth, 0.5*map.clientHeight]);
      this.projection = projection.center([c.lon-this.offset[0], c.lat-this.offset[1]]);
      this.path = d3.geoPath().projection(this.projection);
    },
    /* Calculate the distance between 2 latitude/longitude references */
    getDistanceInKm: function(from, to) {
      try {
        const deg2rad = (d) => d * Math.PI/180.0;
        const R = 6371; // Radius of the earth in km
        const f = {lat: deg2rad(from.lat), lon: deg2rad(from.lon)};
        const t = {lat: deg2rad(to.lat),   lon: deg2rad(to.lon)};
  
        const dLat = t.lat - f.lat;
        const dLon = t.lon - f.lon;
        const a = Math.sin(dLat/2) * Math.sin(dLat/2) 
              + Math.cos(f.lat) * Math.cos(t.lat) * Math.sin(dLon/2) * Math.sin(dLon/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        return R * c; // Distance in km
      } catch (e) {
        this.on_error("Error in getDistanceFromKm", e);
      }
    },

    /* Error handling */
    on_error: function(msg, e) {
      this.$emit("on_error", {message: msg, error: e});
    }
  }
};
</script>
