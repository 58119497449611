<template>
RED has developed a bespoke web-based data centre PUE estimation app named 'assuRED'. 
This app provides climate and efficiency metrics for data centres for a user selected 
location and common design configurations, showcasing RED's expertise in data centre energy 
efficiency and sustainability analysis. This expertise can be coupled with costs and 
economic analysis to enable life-cycle costing that includes not only energy and water 
costs, but also maintenance and equipment life-span.
<br><br>
Based off a data centre IT load of 4.8MW, assuRED estimates PUE for Tier 2, 3 and 4 
systems. The Tier of a data centre indicates its degree of reliability and resilience, 
with higher Tiers indicating greater fault tolerance. The assuRED app evaluates PUE for 
three distinct data centre cooling strategies:
<br>
<li>Air Cooled Chiller with adiabatic free-cooling.</li>
<li>Indirect Evaporative Cooling (IEC) units, with a DX top-up.</li>
<li>Water Cooled Chiller, supported by open-circuit cooling towers.</li>
<br>
How does assuRED actually work? It combines a numerical representation of digital models 
of generic data centre designs with historical data to provide energy efficiency 
estimation. The digital models are made possible by RED's in-house energy modelling software. 
Briza, as it is known, was developed internally at RED to provide engineers with the 
capability to deliver analysis of data centre KPIs, such as PUE, Water Usage Effectiveness 
(WUE) and Carbon Usage Effectiveness (CUE) during the design process. To learn more about 
Briza and how we perform energy efficiency analysis at RED, visit our
<a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" 
   href="https://www.red-eng.com/services/ict/what-is-pue/">
  website.
</a>
</template>
<script>
export default {
  name: "AssuredInfoBody",
  }
</script>
