<template>
A data centre (also spelled data center) is a specialised facility that houses IT systems 
such as computers, servers, networking equipment or storage systems. Data centres provide the 
infrastructure and resources necessary for data processing, storage, and communication. They 
serve as the backbone for digital services, applications, and online platforms as we know them
today.
<br><br>
Data centres provide a secure, reliable, and efficient environment for these services and 
applications, particularly data-intensive ones. They have extensive power supply systems, 
backup power generators, cooling systems, fire suppression mechanisms and physical security 
measures to maintain uninterrupted operations 24 hours a day, 365 days a year.
<br><br>
Due to the nature of IT equipment that requires constant power supply and a regulated environment,
data centre energy demand is substantial. As the demand for data in the digital era continues to 
surge annually, the industry grapples with sustainability challenges. With sustainability a core 
principle of RED Engineering Design, our objective is to optimise data centre energy efficiency by 
benchmarking data centre Power Usage Effectivness (PUE) as early as possible to ensure that energy 
efficiency and sustainability are considered from project inception - including at site selection - 
all the way through to operation.
</template>
<script>
export default {
  name: "DataCentreInfoBody",
}
</script>
