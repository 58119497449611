import tailwindCssForms from "@tailwindcss/forms";

/** @type {import('tailwindcss').Config} */
export default {
  content: [
    './src/index.html',
    './src/**/*.{vue,js}',
  ],
  theme: {
    extend: {
      fill: {
        'transparent': '#0000ffff',
        'assured-blue-400': '#5c95d6',
      },
      stroke: {
        'assured-blue-100': '#d6e4f5',
        'assured-blue-800': '#143152',
        'primary-navy-650': '#1041a2',
      },
      colors: {
        // Color shade/tint taken from https://mdigi.tools/color-shades/
        'primary-navy': {
          DEFAULT: '#0d3482',
           50: '#e8effd',
          150: '#b9cef8',
          250: '#8baef3',
          350: '#5d8def',
          450: '#2e6dea',
          550: '#1553d1',
          650: '#1041a2',
          750: '#0c2e74',
          850: '#071c46',
          950: '#020917'
        },
        'accent-skyblue': {
          DEFAULT: '#459dbd',
           50: '#ecf5f8',
          150: '#c7e1eb',
          250: '#a1cdde',
          350: '#7bbad0',
          450: '#56a6c3',
          550: '#3c8ca9',
          650: '#2f6d84',
          750: '#214e5e',
          850: '#142f38',
          950: '#071013'
        },
        'accent-teal': {
          DEFAULT: '#60bcb9',
           50: '#edf7f7',
          150: '#c9e8e7',
          250: '#a5d9d8',
          350: '#81cac8',
          450: '#5ebbb8',
          550: '#44a19e',
          650: '#357e7b',
          750: '#265a58',
          850: '#173635',
          950: '#081212'
        },
        'accent-green': {
          DEFAULT: '#6cac51',
           50: '#f0f7ee',
          150: '#d3e7cb',
          250: '#b6d6a8',
          350: '#99c686',
          450: '#7bb663',
          550: '#629c49',
          650: '#4c7939',
          750: '#365729',
          850: '#213418',
          950: '#0b1108'
        },
        'accent-lime': {
          DEFAULT: '#d5da4f',
           50: '#fafbea',
          150: '#f0f2c0',
          250: '#e6e996',
          350: '#dce06c',
          450: '#d2d741',
          550: '#b8be28',
          650: '#8f931f',
          750: '#666916',
          850: '#3d3f0d',
          950: '#141504'
        },
        'accent-yellow': {
          DEFAULT: '#fde04f',
           50: '#fffbe6',
          150: '#fef2b3',
          250: '#fee981',
          350: '#fde04f',
          450: '#fcd71c',
          550: '#e3be03',
          650: '#b09302',
          750: '#7e6901',
          850: '#4c3f01',
          950: '#191500'
        },
        'accent-duskypink': {
          DEFAULT: '#c8b2c0',
        },
        'accent-purple': {
          DEFAULT: '#935096',
        },
        'accent-orange': {
          DEFAULT: '#d77a0d',
        },
        'secondary-grey': {
          DEFAULT: '#3F3F3F',
        },
        'assured-blue': {
           50: '#ebf2fa',
          100: '#d6e4f5',
          200: '#adcaeb',
          300: '#85afe0',
          400: '#5c95d6',
          500: '#337acc',
          600: '#2962a3',
          DEFAULT: '#23538a',
          700: '#1f497a',
          800: '#143152',
          900: '#0a1829',
        }
      }
    },
    fontSize: {
       'xxxs': '.6rem',
       'xs': '.75rem',
       'sm': '.875rem',
       'base': '1rem',
       'lg': '1.125rem',
       'xl': '1.25rem',
       '2xl': '1.5rem',
       '3xl': '1.875rem',
       '4xl': '2.25rem',
       '5xl': '3rem',
       '6xl': '4rem',
    }
  },
  variants: {
    extend: {
      stroke: ['hover'],
    }
  },
  plugins: [
    tailwindCssForms,
  ],
}
